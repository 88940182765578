<template>

  <!-- Card -->

  <b-row class="hr">
    <b-col
      cols="12"
      lg="9"
    >
      <b-card>
        <b-card-title>
          Current Knowledge
        </b-card-title>
        <p>
          Let's find out what you already know about workplace safety. Please place a check mark under the "true" or "false" column in anwser to following questions
        </p>
        <hr>
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <strong>STATEMENT</strong>
          </b-col>

          <b-col
            cols="2"
          >
            <strong class="float-right">TRUE</strong>
          </b-col>
          <b-col
            cols="2"
          >
            <strong class="float-right">FALSE</strong>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>1. Falls are a leading cause of death for persons over 65</p>
          </b-col>
          <b-col
            cols="2"
            lg="2"
          >
            <!-- <input
              v-model="form.id"
              type="hidden"
            >

            <input
              v-model="form.cid"
              type="hidden"
            > -->
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers1"
                v-model="answers.answers1"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-1"
                value="true"
                title="Statement 1"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
            lg="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers1"
                v-model="answers.answers1"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-1"
                value="false"
                title="Statement 1"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score1"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #2 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>2. All fire extinguishers are the same</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers2"
                v-model="answers.answers2"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-2"
                value="true"
                title="Statement 2"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers2"
                v-model="answers.answers2"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-2"
                value="false"
                title="Statement 2"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score2"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #3 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>3. Your skin is the first line of defense against germs</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers3"
                v-model="answers.answers3"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-3"
                value="true"
                title="Statement 3"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers3"
                v-model="answers.answers3"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-3"
                value="false"
                title="Statement 3"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score3"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #4 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>4. A fire extinguishers will last for 3 minutes</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers4"
                v-model="answers.answers4"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-4"
                value="true"
                title="Statement 4"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers4"
                v-model="answers.answers4"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-4"
                value="false"
                title="Statement 4"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score4"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #5 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>5. Exhaustion can reduce your tolerance to germs</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers5"
                v-model="answers.answers5"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-5"
                value="true"
                title="Statement 5"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers5"
                v-model="answers.answers5"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-5"
                value="false"
                title="Statement 5"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score5"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #6 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>6. Balance for the elderly takes practice</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers6"
                v-model="answers.answers6"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-6"
                value="true"
                title="Statement 6"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers6"
                v-model="answers.answers6"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-6"
                value="false"
                title="Statement 6"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score6"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #7 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>7. Lift with your back not your legs</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers7"
                v-model="answers.answers7"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-7"
                value="true"
                title="Statement 7"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers7"
                v-model="answers.answers7"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-7"
                value="false"
                title="Statement 7"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score7"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #8 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>8. When using a fire extinguishers you should start at the top of the fire and work your way down.</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers8"
                v-model="answers.answers8"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-8"
                value="true"
                title="Statement 8"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers8"
                v-model="answers.answers8"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-8"
                value="false"
                title="Statement 8"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score8"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #9 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>9. When lifting a heavy load you should keep your legs together and lift with your leg muscles</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers9"
                v-model="answers.answers9"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-9"
                value="true"
                title="Statement 9"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers9"
                v-model="answers.answers9"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-9"
                value="false"
                title="Statement 9"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score9"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #10 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>
              10. If doing several chores, leave the cleanest for last so as to reduce the spread of germs
            </p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers10"
                v-model="answers.answers10"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-10"
                value="true"
                title="Statement 10"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers10"
                v-model="answers.answers10"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-10"
                value="false"
                title="Statement 10"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score10"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #11 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>
              11.  The WHMIS classification for biohazardous infectious material is the one we are most concerned with as caregivers
            </p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers11"
                v-model="answers.answers11"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-11"
                value="true"
                title="Statement 11"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers11"
                v-model="answers.answers11"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-11"
                value="false"
                title="Statement 11"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score11"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #12 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>
              12. Exhaustion is one of the least common complaints among workers, especialy women
            </p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers12"
                v-model="answers.answers12"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-12"
                value="true"
                title="Statement 12"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers12"
                v-model="answers.answers12"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-12"
                value="false"
                title="Statement 12"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score12"
              size="20"
            />
          </b-col>
        </b-row>
        <hr>
        <!-- Q #13 -->
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <p>13. Always look an animal straight in the eye</p>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers13"
                v-model="answers.answers13"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-13"
                value="true"
                title="Statement 13"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="float-right ml-2 center">
              <b-form-radio
                ref="answers13"
                v-model="answers.answers13"
                type="radio"
                :disabled="disabledRadioBtn"
                name="question-13"
                value="false"
                title="Statement 13"
              />
            </div>
          </b-col>
          <b-col
            cols="1"
            lg="1"
            class="feather-icon text-right"
          >
            <feather-icon
              :icon="scoreIcon.score13"
              size="20"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>
        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr19/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          class="mb-75"
          block
          :disabled="isNotSaved"
          @click.prevent="saveForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form18', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form20', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BRow, BCol, BButton, BFormRadio,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
// import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ref, onUnmounted } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'
import store from '@/store'
import router from '@/router'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    BFormRadio,

    vSelect,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr19'
    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })
    if (router.currentRoute.params.action === 'add') {
      // Do something
    }
    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const userData = ref(getUserData())

    return {
      formsOptions,
      userData,
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.query.id,
      action: this.$route.params.action,

      isNotSaved: false,

      forms: this.$route.name,

      required,
      email,
      answers: {
        answers1: '',
        answers2: '',
        answers3: '',
        answers4: '',
        answers5: '',
        answers6: '',
        answers7: '',
        answers8: '',
        answers9: '',
        answers10: '',
        answers11: '',
        answers12: '',
        answers13: '',
      },
      answerKey: [
        'true',
        'false',
        'true',
        'false',
        'true',
        'true',
        'false',
        'false',
        'false',
        'true',
        'true',
        'false',
        'false',
      ],
      scoreIcon: {
        score1: '',
        score2: '',
        score3: '',
        score4: '',
        score5: '',
        score6: '',
        score7: '',
        score8: '',
        score9: '',
        score10: '',
        score11: '',
        score12: '',
        score13: '',
      },
      score: 0,
      disabledRadioBtn: false,
    }
  },
  created() {
    if (this.$route.params.action === 'edit') {
      this.fetchForm19()
    }
    if (this.$route.params.action === 'add') {
      this.fetchForm19()
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    saveForm() {
      const self = this

      let valid = 0
      for (let j = 0; j < 14; j += 1) {
        // eslint-disable-next-line
        const key1 = 'answers' + j
        if (this.answers[key1] === '') {
          valid += 1
        }
      }
      /* for (let i = 1; i < 14; i += 1) {
        if (valid === 0) {
          // eslint-disable-next-line
          const key1 = 'answers' + i
          // eslint-disable-next-line
          const scoreKey = 'score' + i
          if (this.answers[key1] === this.answerKey[i - 1]) {
            this.score += 1
            this.scoreIcon[scoreKey] = 'CheckCircleIcon'
          } else {
            this.scoreIcon[scoreKey] = 'XCircleIcon'
          }
        }
      } */

      if (valid === 0) {
        if (this.action === 'add' || this.action === 'edit') {
          const formData = {
            id: this.id,
            score: this.score,
            answers: this.answers,
          }

          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true
              store
                .dispatch('app-hr19/addForm19', formData)
                .then(response => {
                  console.log(response)
                  this.disabledRadioBtn = true

                  self.isNotSaved = true

                  Swal.fire({
                    icon: 'success',
                    // eslint-disable-next-line
                    // title: this.score + ' correct answer out of 13',
                    title: 'Saved!',
                    text: 'Saved succesfully.',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(result1 => {
                    console.log(result1)

                    for (let i = 1; i < 14; i += 1) {
                      // eslint-disable-next-line
                      const key1 = 'answers' + i
                      // eslint-disable-next-line
                      const scoreKey = 'score' + i
                      if (this.answers[key1] === this.answerKey[i - 1]) {
                        this.score += 1
                        this.scoreIcon[scoreKey] = 'CheckCircleIcon'
                      } else {
                        this.scoreIcon[scoreKey] = 'XCircleIcon'
                      }
                    }
                  })

                  this.score = 0
                })
                .catch(error => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  console.error(error)
                })
                /* .finally(() => {
                  self.isNotSaved = false
                }) */
            }
          })
        }
      } else {
        this.$swal({
          title: 'Answer all Questions',
          icon: 'warning',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn btn-secondary',
          },
          buttonsStyling: false,
        })
      }
    },
    fetchForm19() {
      store
        .dispatch('app-hr19/fetchForm19', { id: this.id })
        .then(response => {
          /* if (response.data === null) {
            this.disabledRadioBtn = true
          } */
          try {
            const obj = JSON.parse(response.data.response.data[0].answers)

            if (obj !== null) {
              this.answers = obj.answers
              for (let i = 1; i < 14; i += 1) {
                // eslint-disable-next-line
                const key1 = 'answers' + i
                // eslint-disable-next-line
                const scoreKey = 'score' + i
                if (this.answers[key1] === this.answerKey[i - 1]) {
                  this.score += 1
                  this.scoreIcon[scoreKey] = 'CheckCircleIcon'
                } else {
                  this.scoreIcon[scoreKey] = 'XCircleIcon'
                }
              }

              this.disabledRadioBtn = true

              this.isNotSaved = true
            }
          } catch (error) {
            console.error(error)
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style >
.input-label> label {
    height: 40px;
}
.hr-break{
  border-bottom:1px solid lightgray;
  margin-bottom:10px;
}
.feather-icon >.feather-check-circle{
  color: green!important;
  width: 18px;
}
.feather-icon >.feather-x-circle{
  color: red!important;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
